@-moz-keyframes problemCounter {
    100% { -moz-transform: rotateX(360deg); }
}
@-webkit-keyframes problemCounter {
    100% { -webkit-transform: rotateX(360deg); }
}
@keyframes problemCounter {
    100% {
        -webkit-transform: rotateX(360deg);
        transform: rotateX(360deg);
    }
}

.eurekamaths-bloc:hover img {
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}


@include media-breakpoint-up(md) {
    #carousel {
        position: relative;
        height: 586px;
        overflow: hidden;
    }

    #carousel>div {
        position: absolute;
        transition: transform 1s, left 1s, opacity 1s, z-index 0s, width 1s;

        & a.btn {
            height: 160px;
            transition: height 1s, width 1s;
            pointer-events: all;

            &:hover {
                background-color: $secondary;
                border-color: $secondary;
            }
        }

        &.prev {
            left: 18%;
        }

        &.selected {
            z-index: 1;
            left: 50%;
            transform: translateY(0px) translateX(-50%);
            width: 50%;

            &>a.btn img, &>a.btn svg {
                height: 50px;
                width: 78px;
                transition: height 1s, width 1s;

                @include media-breakpoint-up(lg) {
                    height: 64px;
                    width: 100px;
                    transition: height 1s, width 1s;
                }
            }

            &>a.btn.eurekamaths-bloc img {
                height: 64px;
                width: 64px;
                transition: height 1s, width 1s;
            }

            & h2 {
                font-size: 2rem;
                transition: font-size 1s;
            }

            & h3 {
                font-size: 1.75rem;
                transition: font-size 1s;
            }
        }

        &.next {
            left: 82%;
        }

        &.prev, &.next {
            z-index: 0;
            width: 35%;
            opacity: 0.7;
            transform: translateY(50px) translateX(-50%);
            filter: grayscale(0.4);

            & h2 {
                font-size: 1.4rem;
                transition: font-size 1s;
            }

            &>div {
                pointer-events: none;
            }

            &>a.btn {
                height: 130px;
                pointer-events: none;
                transition: height 1s, width 1s;

                & svg {
                    height: 32px;
                    width: 32px;
                    transition: height 1s, width 1s;
                }

                & img {
                    height: 32px;
                    width: 50px;
                    transition: height 1s, width 1s;
                }

                &.eurekamaths-bloc img {
                    width: 32px;
                    transition: width 1s;
                }

                & h3 {
                    font-size: 1.3rem;
                    transition: font-size 1s;
                }
            }
        }
    }
}

#carousel {
    flex-direction: column;
    display: flex;

    &>div img {
        height: 64px;
        width: 100px;
    }

    &>div .eurekamaths-bloc img {
        height: 64px;
        width: 64px;
    }

    &>div>a, &>div>div {
        border-radius: 10px;
    }
}
