// sass src/styles/scss/app.scss src/styles/app-1.0.0.css

$pagination-hover-bg: #809dcf;
$pagination-hover-border-color: #809dcf;
$pagination-hover-color: white;

// Custom color
$blue-0: #b3c4e2;
$blue-1: #99b1d8;
$blue-2: #809dcf;
$blue-3: #6689c5;
$blue-4: #4d76bc;

$blue-6: #2d569c;
$blue-7: #264a85;
$blue-8: #203d6f;
$blue-9: #1a3159;
$blue-10: #132543;

@import "../../../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "../../../node_modules/bootstrap/scss/functions";

$primary: #3065b0;
$secondary: #ffb822;
$body-color: #333;

$nav-link-color: rgba(255, 255, 255, 0.55);
$nav-link-hover-color: rgba(255, 255, 255, 0.75);

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
);

@import "../../../node_modules/bootstrap/scss/variables";
$border-color: rgba($black, 0.2);

@import "../../../node_modules/bootstrap/scss/variables-dark";

$ternary: #404040;
$custom-colors: (
  "ternary": $ternary,
  "lightgrey": lightgrey,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";

@import "../../../node_modules/bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "max-width":
      map-merge(
        map-get($utilities, "max-width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "max-width"), "values"),
              (
                50: 50%,
              )
            ),
        )
      ),
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                10: 10%,
                20: 20%,
                30: 30%,
                40: 40%,
                60: 60%,
                70: 70%,
                80: 80%,
                90: 90%,
              )
            ),
        )
      ),
  )
);

// Layout & components
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/nav";

$navbar-padding-y: 0px;

@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/accordion";
// @import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/alert";
// @import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/list-group";
$btn-close-focus-shadow: "none";
@import "../../../node_modules/bootstrap/scss/close";
// @import "../../../node_modules/bootstrap/scss/toasts";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";
// @import "../../../node_modules/bootstrap/scss/popover";
// @import "../../../node_modules/bootstrap/scss/carousel";
// @import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

@import "./form-template";
@import "./header";
@import "./carousel";

p {
  margin: 0px;
  padding: 0px;
}

// Boutton selection problème

button.select-problem {
  background-color: $primary;
  color: white;
}
button.select-problem:hover {
  background-color: $blue-6;
  color: white;
}

button.button-select-problem:not(.select-problem):hover {
  background-color: $blue-1;
  border-color: $blue-1;
}

.edition-dropdown li button:hover {
  background-color: darkgoldenrod;
}

// Body + footer

body #root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body #root div.container-fluid {
  flex: 1 0 auto;
  padding-top: 5px;
}

// list group photos

.list-group-problem-photo {
  flex-direction: column;
  justify-content: center;
}
@include media-breakpoint-up(md) {
  .list-group-problem-photo {
    flex-flow: row wrap;
  }
  .list-group-problem-photo > * {
    width: 48%;
    margin: 0.25 * $spacer;
  }
}
// Grade Progess Bar

.grade-progress-bar {
  -webkit-animation: progress 2s normal ease-out forwards;
  animation: progress 2s normal ease-out forwards;
}

@-webkit-keyframes progress {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes progress {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

.image-photo:hover {
  cursor: zoom-in;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: $ternary;
}

.nav-pills .nav-link:not(.active) {
  color: $ternary;
  background-color: white;

  &:hover {
    color: grey;
  }
}

// Type dropdown
.type-dropdown + ul {
  display: none;
}

.type-dropdown:hover + ul {
  display: block;
}

.modal .modal-header {
  background-color: $primary;
  color: white;

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6 {
    margin-bottom: 0;
  }
}
.fade.modal.show {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-background.show {
  opacity: 0;
}
